@import "../../../styles/base/abstracts";

.hbs-keyword-scroll-list {
  overflow: hidden;
  min-width: 100%;
  margin-left: -$grid-padding-mobile * 2;
  margin-right: -$grid-padding-mobile * 2;
  position: relative;

  &::before,
  &::after {
    @include absolute(0 x);
    width: 50px;
    background: linear-gradient(90deg, $c-bg, $c-bg-transparent);
    content: "";
    z-index: 1;
  }

  &::before {
    left: 0;
  }
  &::after {
    right: 0;
    transform: rotate(180deg);
  }

  @include mq($bp-full-grid) {
    margin-left: -$grid-padding * 2;
    margin-right: -$grid-padding * 2;

    &::after {
      width: 100px;
    }
  }

  .hbs-keyword-scroll-list__row {
    @include fluid-type(
      (
        $bp-mobile: $fs-lg,
        $bp-desktop-xl: $fs-xxxxl,
      )
    );
    @include padding($spacing-sm);

    line-height: $lh-h1;
    letter-spacing: -0.03em;
    white-space: nowrap;
    display: inline-block;
    // transition: transform;

    // transition: 0.05s linear;
    @media (prefers-reduced-motion: no-preference) {
      &:nth-child(odd) {
        transform: translateX(
          calc(
            (var(--keyword-scroll-offset) * -100%) +
              ((var(--window-width) * (var(--keyword-scroll-offset))))
          )
        );
      }

      &:nth-child(even) {
        transform: translateX(
          calc(
            (var(--keyword-scroll-offset) * 100% - 100%) +
              (
                var(--window-width) -
                  (var(--window-width) * var(--keyword-scroll-offset))
              )
          )
        );
      }
    }
  }

  .hbs-keyword-scroll-list__keyword {
    text-decoration: none;

    &:not(:last-child):after {
      content: "";
      height: 0.95em;
      border-right: 1px solid;
      color: $c-crimson;
      display: inline-block;
      margin-bottom: -0.15em;
      margin-left: 0.4em;
      margin-right: 0.4em;
      transform: rotate(15deg);
    }
  }
}
